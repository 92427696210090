<template>
  <transition name="fade">
    <div class="daily-modal" v-if="isOpen">
      <div class="daily-modal__container">
        <button class="daily-modal__close button -round -white" @click="isOpen = false">
          <Icon id="close"/>
        </button>
        <div class="daily-modal__content">

          <div class="daily-modal__hat"> {{ hat }} </div>

          <h2 class="daily-modal__title"> {{ title }} </h2>

          <p class="daily-modal__text" v-html="text"/>

          <Image :source="message.image" class="daily-modal__media" />

          <button class="button -red daily-modal__button" @click="button.action" v-if="button.label"> {{ button.label }} </button>
        </div>
      </div>

    </div>
  </transition>
</template>

<script>
import {Image} from '@/components/global'

export default {
  name: "DailyModal",
  components: {Image},
  data() {
    return {
      isOpen: false
    }
  },
  watch: {
    message : {
      handler: function (to) {
        if (to) this.isOpen = true
      },
      immediate: true
    }
  },
  computed: {
    message() {
      return this.$beet.message
    },
    hat() {
      return this.message?.date[this.$i18n.locale] || 'Dimanche le 4 Février'
    },
    title() {
      return this.message?.title[this.$i18n.locale] || 'À ne pas manquer aujourd\'hui'
    },
    text() {
      return this.message?.content[this.$i18n.locale] || 'Découvrez les nouvelles activités inusitées qui brisent les conventions et redécouvrez vos événements chouchous. Pour toute la famille et entre amis, en journée comme en soirée, venez festoyez avec nous!'
    },
    button() {
      let action
      const label = this.message?.btnText[this.$i18n.locale] || 'Voir toutes les activités d\'aujourd\'hui'
      const link = this.message?.btnLink[this.$i18n.locale]
      if(link) {
        if(link?.includes('https')) {
          action = () => {
            window.open(link, 'self')
            this.isOpen = false
          }
        } else {
          action = () => {
            this.$router.push(link)
            this.isOpen = false
          }
        }
      } else {
        action = () => {
          this.$router.push({name: this.$routes.HOME.ACTIVITIES})
          this.isOpen = false
        }
      }
      return { label, action }
    }
  }
}
</script>

